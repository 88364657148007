<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">帮助中心</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addHelp()" size="mini">创建</el-button>
            </div>
        </div>
        <el-table :data="helpTable" style="width: 100%; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}"
                  :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
            <el-table-column prop="title" label="标题">
                <template slot-scope="scope">
                    <span class="help-name">{{scope.row.title}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="help_id_name" label="分类" width="160" align="center"></el-table-column>
            <el-table-column prop="create" label="时间" width="160" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="240">
                <template slot-scope="scope">
                    <el-button type="text" @click="viewHelp(scope.row)">查看</el-button>
                    <el-button type="text" @click="editHelp(scope.row)">编辑</el-button>
                    <el-button type="text" @click="deleteHelp(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="helpPages.currentPageNum"
                :page-size="helpPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="helpPages.total"
                @current-change="helpCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "HelpCenter",
        data() {
            return {
                //帮助列表数据
                helpTable: [],
                //分页
                helpPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
          this.getHelpList();
        },
        methods: {
            //帮助列表
            getHelpList() {
                let param = {
                    paging: 1,
                    page: this.helpPages.currentPageNum,
                    pageSize: this.helpPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.help, param, (res) => {
                    if (res.code === 200) {
                        this.helpTable = res.data.data;
                        this.helpPages.total = res.data.total;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //创建帮助
            addHelp() {
                this.$router.push('/admin/help/create');
            },
            //查看
            viewHelp(row) {
                this.$router.push({
                    path: '/admin/help/detail',
                    query: {
                        id: row.id
                    }
                })
            },
            //编辑
            editHelp(row) {
                this.$router.push({
                    path: '/admin/help/create',
                    query: {
                        id: row.id
                    }
                })
            },
            //删除
            deleteHelp(row) {
                this.$confirm('删除文章后不能恢复，确定是否删除？', '删除文章', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.helpDelete, {id: row.id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            //分页
            helpCurrentChange(val) {
                this.helpPages.currentPageNum = val;
                this.getHelpList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .admin-top {
        align-items: center;
    }
    .el-table {
        ::v-deep tbody .el-table__row td {
            border-bottom: 1px solid #F6F5F8;
            .cell {
                .help-name {
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                button span {
                    color: #1D33E1;
                    &:hover {
                        color: #495df1;
                    }
                }
            }
        }
    }

</style>